export default class Hospital {
  hojinCd: string;
  hospitalCd: string;
  hospitalNm: string;
  kana: string;
  isValid: string;
  constructor(
    hojinCd = "",
    hospitalCd = "",
    hospitalNm = "",
    kana = "",
    isValid = ""
  ) {
    this.hojinCd = hojinCd;
    this.hospitalCd = hospitalCd;
    this.hospitalNm = hospitalNm;
    this.kana = kana;
    this.isValid = isValid;
  }
}
