<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          v-model="selectHojin"
          :items="Hojins"
          label="法人"
          :rules="[Rules.Required]"
          outlined
          :disabled="!add"
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
          @change="changeKbn()"
        >
        </v-autocomplete>
        <v-text-field
          v-model="hospitalCd"
          label="病院コード"
          :rules="[Rules.Required]"
          outlined
          :disabled="!add"
          prepend-icon="mdi-text"
          maxlength="50"
        ></v-text-field>
        <v-text-field
          v-model="hospitalNm"
          label="病院名"
          prepend-icon="mdi-text"
          outlined
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-text-field
          v-model="hospitalkana"
          label="ふりがな"
          prepend-icon="mdi-text"
          outlined
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-radio-group
          v-model="selectDelete"
          row
          label="削除フラグ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="削除" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";
import Hospital from "@/models/Hospital";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const SelectsDelete = {
  Delete: 0,
  Default: 1
};

export default {
  name: "HospitalDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      selectHojin: { code: "", name: "" },
      Hojins: [],
      hospitalCd: "",
      eventname: "",
      selectDelete: SelectsDelete.Default,
      SelectsDelete,
      hospitalN: "",
      hospitalkana: ""
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.add);

      if (this.hospitalCd != null && this.hospitalCd.length != 3) {
        this.$error("コードを3桁で入力してください。");
        return;
      }

      if (this.hospitalCd != null && isNaN(this.hospitalCd)) {
        console.log("code", this.hospitalCd.substr(0, 1));
        if (this.hospitalCd.substr(0, 1) == "0") {
          console.log("code", this.hospitalCd.substr(1, 1));
          if (this.hospitalCd.substr(1, 1) == "0") {
            console.log("code", this.hospitalCd.substr(2, 1));
            if (isNaN(this.hospitalCd.substr(2, 1))) {
              this.$error("コードを数値で入力してください。");
              return;
            }
          } else if (isNaN(this.hospitalCd.substr(2, 2))) {
            this.$error("コードを数値で入力してください。");
            return;
          }
        } else {
          this.$error("コードを数値で入力してください。");
          return;
        }
      }

      this.$loading();

      try {
        const param = new Hospital(
          this.selectHojin.code,
          this.hospitalCd,
          this.hospitalNm,
          this.hospitalkana,
          this.selectDelete
        );

        console.log("submit_param", param);

        if (this.add) {
          const data = await this.$post(this.Paths.hospital, param);
          this.recordId = data.recordId;
          this.add = false;
        } else {
          await this.$put(this.Paths.hospital, param);
        }

        this.$info("更新しました。", "病院情報");
        this.edit = false;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/hospital";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HJN);
      this.Hojins = filter.filter(e => e.code !== NotSetCode.HJN);
    },
    async changeKbn() {
      const param = {
        hojinCd: this.selectHojin.code
      };
      const encode = encodeURI(JSON.stringify(param));

      const result = await this.$get(
        this.Paths.hospitalrnewcode,
        "query=" + encode
      );

      console.log("result", result);

      if (result != null && result.hospitalCd != null) {
        this.hospitalCd = result.hospitalCd;
      }
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;

    this.$loading();
    try {
      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      await this.getCodeMasterItems();
      this.getPullDownItems();

      if (!this.add) {
        const HojinCd = this.args.hojinCd == null ? "0" : this.args.hojinCd;
        const filterHojin = this.Hojins.filter(e => e.code === HojinCd);
        if (filterHojin.length > 0) {
          this.selectHojin.code = filterHojin[0].code;
          this.selectHojin.name = filterHojin[0].name;
        }

        this.hospitalCd = this.args.hospitalCd;
        this.hospitalNm = this.args.hospitalNm;
        this.hospitalkana = this.args.kana;

        if (this.args.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
